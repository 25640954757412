import React from "react";
import { Grid, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { Image } from "../image/Image";
import { SpecificTheme } from "../../theme/default";
import { compose } from "recompose";
import { BlogPostQuery_contentfulBlogPost_author } from "../../templates/__generated__/BlogPostQuery";

type AuthorProps = {
  language: string;
  author: BlogPostQuery_contentfulBlogPost_author;
};

type AuthorInnerProps = AuthorProps & WithStyles<typeof styles>;

const Author: React.FC<AuthorInnerProps> = ({ author, classes, language }) => {
  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Image image={author.image} className={classes.image} />
        {/*<GatsbyImage
          className={classes.image}
          alt={author.image.description}
          title={author.image.title}
          fluid={author.image.fluid}
        />*/}
      </Grid>
      <Grid
        className={classes.authorDescription}
        item
        dangerouslySetInnerHTML={{
          __html:
            language === "de"
              ? author.intro.childMarkdownRemark.html
              : author.introEnglish.childMarkdownRemark.html
        }}
      />
    </Grid>
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    container: {
      borderTop: "2px solid lightgrey"
    },
    image: {
      marginTop: "1rem",
      marginBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        width: "30vw"
      },
      [theme.breakpoints.up("md")]: {
        width: "20vw"
      },
      [theme.breakpoints.up("lg")]: {
        width: "15vw"
      }
    },
    authorDescription: {
      marginLeft: "20px",
      [theme.breakpoints.down("lg")]: {
        width: "60vw"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
    }
  });

export default compose<AuthorInnerProps, AuthorProps>(withStyles(styles))(
  Author
);
