import React from "react";
import { graphql } from "gatsby";

import MainLayout from "../components/layout/MainLayout";
import {
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Theme
} from "@material-ui/core";
import { get } from "../helpers/data";
import { PageProps } from "../typings";
import { BlogPostQuery } from "./__generated__/BlogPostQuery";
import Footer from "../components/general/Footer";
import Container from "../components/general/Container";
import Author from "../components/blog/Author";
import SEO from "../components/SEO";

type BlogPostProps = {} & PageProps<BlogPostQuery> & WithStyles<typeof styles>;

const blog: React.FC<BlogPostProps> = ({ classes, data, location }) => {
  const post = get(data, "contentfulBlogPost");
  return (
    <MainLayout location={location} title={post.title}>
      <Container
        variant="standard"
        margin
        color="white"
        className={classes.container}
      >
        <Typography variant="h1" className={classes.title}>
          {post.title}
        </Typography>
        <Typography variant="subtitle2" className={classes.subTitle}>
          {post.language === "en" ? "by" : "von"} {post.author.name}
        </Typography>

        <div
          dangerouslySetInnerHTML={{
            __html: post.content.childMarkdownRemark.html,
          }}
          className={classes.content}
        />

        <Author author={post.author} language={post.language} />
      </Container>
      <Footer />

      <SEO
        blogPosting={{
          author: {
            name: post.author.name,
            twitter: post.author.twitter,
          },
          headline: post.title,
          datePublished: post.SEOPublishDate,
          dateModified: post.SEOUpdateDate,
          description: post.previewText.childMarkdownRemark.excerpt,
          image: {
            title: post.seoImage.title,
            url: post.seoImage.fixed.src,
            width: post.seoImage.fixed.width,
            height: post.seoImage.fixed.height,
          },
          section: post.seoSection,
          tags: post.seoTags ? post.seoTags : [],
          publishingBlog: post.publishingBlog
        }}
        general={{
          title: post.title,
          contentType: "article",
          description: post.previewText.childMarkdownRemark.excerpt,
          language: post.language as any,
          path: location.pathname,
        }}
      />
    </MainLayout>
  );
};

const styles = (theme: Theme) => createStyles({
  container: {
    padding: "0 10px"
  },
  content: {
    "& thead": {
      textAlign: "left"
    },
    "& tr > th > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem"
    },
    "& tr > td > p": {
      marginBottom: "0.125rem",
      marginTop: "0.125rem"
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main
    },
  },
  title: {
    margin: "1rem 0 0 0"
  },
  subTitle: {}
});

export default withStyles(styles)(blog);

export const blogPostQuery = graphql`
         query BlogPostQuery($slug: String!) {
           contentfulBlogPost(slug: { eq: $slug }) {
             id
             title
             seoSection
             seoTags
             language
             content {
               childMarkdownRemark {
                 html
                 rawMarkdownBody
               }
             }
             seoImage {
               title
               description
               fixed(width: 800, height: 800, quality: 95) {
                 ...ContentfulFixedImage
               }
             }
             publishingBlog
             previewText {
               childMarkdownRemark {
                 excerpt(pruneLength: 300)
               }
             }

             SEOPublishDate: publishDate(formatString: "YYYY-MM-DD")
             SEOUpdateDate: updatedAt(formatString: "YYYY-MM-DD")
             author {
               name
               twitter
               image {
                 title
                 description
                 fluid(maxHeight: 500) {
                   ...ContentfulFluidImage
                 }
               }
               intro {
                 childMarkdownRemark {
                   html
                 }
               }
               introEnglish {
                 childMarkdownRemark {
                   html
                 }
               }
             }
           }
         }
       `;
